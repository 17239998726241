<template>
    <div>
        <b-card no-body>
            <b-card-header>
                 <h5>Add to Dashboard</h5>
            </b-card-header>
            <b-card-body>
        <b-row>
             <b-col cols="12" md="12" class="mb-md-0 mb-2">
      <b-form-group>
        <v-select
          v-model="selected"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          label="title"
          :options="option"
          placeholder="Select a Saved Chart"
          :searchable="false"
        />
      </b-form-group>
    </b-col>
        </b-row>
        </b-card-body>
          <footer>
           <div class="text-right">
         <b-button
         variant="flat-success"
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      @click="createGraph"
    >
    <feather-icon
    icon="CheckIcon"/>
     Add
    </b-button>
    </div>
        </footer>
           </b-card>
             <div class="text-right mt-5 mb-1" v-if="layout.length>0">
        <b-button
        pill
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="outline-danger"
      @click="showModal=!showModal"
    >
    <feather-icon
    icon="SaveIcon"/>
      Save Dash
    </b-button>
    </div>
      <div>
          <grid-layout
          :key="changeChart"
            :layout.sync="layout"
            :col-num="12"
            :row-height="30"
            :is-draggable="true"
            :is-resizable="true"
            :is-mirrored="false"
            :vertical-compact="true"
            :margin="[10, 12]"
            :use-css-transforms="true"
            :responsive="false"
            :breakpoints="breakpoints"
            :cols="cols"
            @layout-ready="layoutReady = true">

        <grid-item v-for="item in layout"
                   :x="item.x"
                   :y="item.y"
                   :w="item.w"
                   :h="item.h"
                   :i="item.i"
                   :key="item.i">
      <apex-multipe-bar-chart :chartTitle="item.chartInfo.title" :cseries="item.chartInfo.cdata" 
      :labels="item.chartInfo.labels" :key="item.chartInfo.chartType.value" :color="['#00E396']" :idref="item.i"
      :eliminar="1" v-if="item.chartInfo.chartType.value==1"/>
  
           <apex-line-area-chart :chartTitle="item.chartInfo.title" :cseries="item.chartInfo.cdata" 
      :labels="item.chartInfo.labels" :key="item.chartInfo.chartType.value" :estado="true" :idref="item.i"
      :eliminar="1" v-if="item.chartInfo.chartType.value==2"/>

  
        <apex-line-area :chartTitle="item.chartInfo.title" :cseries="item.chartInfo.cdata" 
      :labels="item.chartInfo.labels" :key="item.chartInfo.chartType.value" :idref="item.i"
      :eliminar="1" v-if="item.chartInfo.chartType.value==3"/>
        </grid-item>
    </grid-layout>
    </div>

    <div>
       <b-modal
        v-model="showModal"
      title="Save Chart"
      ok-only
      centered
      ok-title="Save"
      @ok="saveDash"
    >
      <b-card-text>
      Do you want to save the Dashboard?
      </b-card-text>
       <b-form-group>
          <b-form-input
          v-model="dashTitle"
            type="text"
            placeholder="Enter the Dashboard Title"
          />
        </b-form-group>
    </b-modal>
    </div>
    </div>
</template>
<script>
import {BCol,BRow,BFormGroup,BButton,BInputGroup,BFormInput,BCard,BCardHeader,BCardBody,BSpinner,
BModal,BCardText} from "bootstrap-vue"
import vSelect from 'vue-select'
import Ripple from "vue-ripple-directive"
import VueGridLayout from 'vue-grid-layout';
 import ApexLineAreaChart from "@/components/charts/apex-chart/chartsquery/ApexLineAreaChart.vue"
 import ApexLineArea from "@/components/charts/apex-chart/chartsquery/ApexLine&Area.vue"
 import ApexMultipeBarChart from "@/components/charts/apex-chart/chartsquery/ApexMultipleBarChart.vue"
export default {
    props:['items','fields','sql','alldata'],
    directives:{Ripple},
    components:{BCol,BRow,BFormGroup,vSelect,BButton,
    BInputGroup,BFormInput,BCard,BCardHeader,BCardBody,BSpinner,BModal,BCardText,
    GridLayout: VueGridLayout.GridLayout,
           GridItem: VueGridLayout.GridItem,
           ApexLineAreaChart,ApexLineArea,ApexMultipeBarChart},
    data(){
        return{
            changeChart:0,
             selected: null,
      option: [], 
      series:[],
      labels:[],
      cambio:0, 
       showSpinner:true,
        showModal: false, 
     layout:[],
      showModal:false,
      dashTitle:null,
        breakpoints: {
          lg: 1200,
          md: 996,
          sm: 768,
          xs: 480,
          xxs: 0
    },
cols: {
          lg: 12,
          md: 10,
          sm: 6,
          xs: 4,
          xxs: 2,
        
    }
    
    }
    },
    mounted(){
        this.loadChart(this.ald)
           this.$root.$on("chartidref",(data) => {
         this.layout=this.layout.filter(dx=>dx.i!=data)

    });
    },
    methods:{ 
        createGraph:function(){
          this.changeChart=1;
          let gridInfo= {
            x: 0, y: 0, w: 12, h: 6, i: '0'
          };
          if (this.selected){
            gridInfo.chartInfo=this.selected;
          }
            console.log(this.selected);
     
            if(this.layout.length>0)
            {
               gridInfo.i=(parseInt(this.layout[this.layout.length-1].i)+1).toString()
           
                    gridInfo.y+=20;
            }
    this.layout.push(gridInfo)
       this.changeChart=2;
        },
        saveDash:function(){
           const vm=this;
           if (this.dashTitle==null) {
                this.$swal({
        title: 'Error!',
        text: 'Insert Dashboard Title!',
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-danger',
        },
        buttonsStyling: false,
      })
           }
           else{
            const data={
        info:this.layout,
        title:this.dashTitle
    }
     const axios = require("axios");
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  axios.post("/api/v1/sel/bdersv",data,config).then(function (response) {
       vm.$swal({
        title: 'Excelent!',
        text: 'Chart saved successfully!',
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    })
    .catch((err) => {
      console.log(err);
    });
    }
        },
           loadChart:function(v){
               const vm=this
     const axios = require("axios");
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  axios.get("/api/v1/sel/bgcin",config).then(function (response) {
    if (response.data.length>0) {
      vm.option=response.data
    }

    })
    .catch((err) => {
      console.log(err);
    });

      this.showSpinner=false
        }
       
    }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>